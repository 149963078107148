import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const List = ({
  type,
  children,
}) => {
  if (type === 'bullet') {
    return <S.BulletList>{children}</S.BulletList>
  }

  return null
};

List.propTypes = {
  type: PropTypes.oneOf(['bullet', 'number']),
};

List.defaultProps = {
  type: 'bullet'
};

export default List;
